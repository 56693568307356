@import '../../../theme/variables.scss';

.engagement-flow {
    &__content {
        gap: 20px;
    }

    &__tab-cont {
        margin-top: 1rem;

        > .custom-tabs {
            margin-top: 0.5rem;
        }

        &__engagement_button {
            height: 2.25rem;
        }
        height: 41px;
    }
    &__name {
        cursor: pointer !important;
    }

    &__link {
        > .body5:hover {
            text-decoration: underline;
        }
        pointer-events: all !important;
    }
}
