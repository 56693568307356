@import '../../../theme/variables.scss';

.actions-bar {
    padding: 0rem 2rem 0rem 2.21875rem;
    min-height: 4rem;
    width: 87%;

    .toast,
    .toast-body,
    .toast-container {
        background: none !important;
    }

    body .toast,
    .toast-body,
    .toast-container {
        --bs-toast-zindex: 0 !important;
        z-index: var(--bs-toast-zindex) !important;
    }
    &__action-icon {
        min-width: 4rem;
        min-height: 4rem;
        &:hover {
            background-color: $primary-500;
            .data-grid__svg-react-icon path {
                fill: $text-black !important;
            }
            .actions-tray__reopen-icon {
                color: $text-black !important;
            }
        }
        &__disabled {
            .data-grid__svg-react-icon path {
                fill: $text-low-emphasis !important;
            }
        }
    }
    &__icon {
        cursor: pointer;
    }

    .subtitle3 {
        margin-bottom: 0px;
    }
}
.toast-container {
    --bs-toast-zindex: 10 !important;
    z-index: var(--bs-toast-zindex) !important;
    display: flex;
    flex-direction: column;
}
