@import '../../../theme/variables.scss';

.toggle-switch {
    .body2,
    .body3 {
        margin-bottom: 0rem !important;
    }
    .form-switch .form-check-input:checked {
        background-color: $primary-500 !important;
    }
    .form-switch .form-check-input {
        width: 2.1875rem !important;
        height: 1.25rem !important;
        box-shadow: none;
        border: none;
        background-color: $text-medium-emphasis;
    }
}
