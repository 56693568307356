@import '../../../theme/variables.scss';

.form-check-input:checked {
    background-color: $primary-500 !important;
}

.data-grid-dropdown__dropdown-item {
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0.25rem 1rem !important;

    color: $text-high-emphasis !important;
    height: 2.5rem;
    .body2 {
        margin-bottom: 0rem;
    }
    &:hover {
        background-color: $primary-500 !important;
        color: $text-black !important;
        > .form-check-input {
            border: 1px solid $text-black !important;
        }
    }
}

.checkbox {
    &__input {
        margin-top: 0.4rem !important;
    }
}
