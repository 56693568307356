@import '../../../theme/variables.scss';

.view-query-modal {
    > .modal-dialog {
        max-width: 75vw;
        > .modal-content {
            border-radius: 8px;
            border: 0.8px solid $structural-stroke-100;
            background: $body-color;
            box-shadow: 0px 0.375rem 1.5625rem 0px $vizualization-black;
            height: 35rem;
            > .modal-header > .close-button {
                cursor: pointer;
                color: $text-high-emphasis !important;
            }
            > .modal-header {
                height: 3.4375rem;
                border-bottom: 1px solid $structural-stroke-100;
            }
            > .modal-body {
                height: calc(100% - 3.4375rem);
            }
        }
    }
    &__editor {
        height: 25rem;
    }
    &__action-editor {
        height: 30rem;
    }
    &__link {
        text-decoration: none;
    }
    &__btn {
        height: 2rem;
    }
    &__icon {
        cursor: pointer;
    }
    .__json-string__ {
        white-space: pre-line;
        vertical-align: bottom;
    }
}
