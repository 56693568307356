@import '../../../theme/variables.scss';

.vendor-details-page {
    height: calc(100% - 4.6875rem);

    .btn {
        --bs-btn-padding-x: 0rem;
        --bs-btn-active-border-color: transparent;
    }

    &__button {
        height: 4.125rem;
        width: 100%;
        gap: 0.625rem;
        align-items: center;
    }

    &__content {
        min-height: 100%;
        border-radius: 0.5rem;
        padding: 1.5rem 1rem 26.3125rem 1rem;
    }
}