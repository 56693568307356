@import '../../../theme/variables.scss';

.tooling-coverage {
    &__styled-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__styled-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__employee-tooling-coverage {
        min-width: 7rem;
        width: 10vw;
        text-overflow: ellipsis;
    }

    &__endpoint-tooling-coverage {
        min-width: 7rem;
        width: 8vw;
        text-overflow: ellipsis;
    }

    &__row-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
    }

    .vr {
        border: 1px solid #756c88;
    }

    &__error-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: '10px';
        align-items: 'center';
    }

    &__alert-icon {
        color: $secondary-accent-red-2;
        transform: rotate(-45deg);
    }

    &__styled-space {
        margin-left: 0.125rem;
    }

    .vr {
        border: 1px solid $secondary-accent-blue-1;
    }

    &__icon {
        cursor: pointer;
        color: $text-low-emphasis;
        position: absolute;
        top: 7%;
        right: 2%;
        transform: translateY(-50%);
    }

    &__header {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .card--rectangle {
        padding: 1.5rem !important;
    }

    .card__title {
        margin-top: -1.5rem !important;
    }
}
