@import '../../../theme/variables.scss';

.settings-page {
    .h4,
    .body11 {
        margin-bottom: 0rem;
    }

    .accordion-body {
        padding-left: 3.2rem;
    }

    &__invite-button {
        border-radius: 0.25rem;
        border: none;
        width: 4.9375rem;
        height: 2.25rem;
    }

    &__icon {
        cursor: pointer;
        color: $text-low-emphasis;

        &:hover {
            color: $text-high-emphasis;
        }
    }

    &__dropdown-container {
        border: 1px solid $text-low-emphasis;
        border-radius: 0.25rem;
    }

    &__disable-icon {
        pointer-events: none;
    }

    .dropdown-toggle {
        border: none !important;

        &[aria-expanded='true'] {
            border: none !important;
        }

        &:hover,
        &:checked {
            border: none !important;
        }
    }

    .input-group {
        width: 67vw;
    }

    .dropdown-menu.show {
        max-height: 12rem !important;
    }

    .chip__user-cohort-variant {
        background: $hover-login-cell !important;
    }

    &__form {
        height: 22px !important;
        width: 35px !important;
    }

    .sub-table {
        max-height: 28vh !important;
    }

    .erKEsr {
        min-height: 21.5rem !important;
    }

    .hLqhfi {
        max-height: 21.5rem !important;
    }
}

.table-skeleton {
    min-width: 0rem !important;
}
