@import '../../../theme/variables.scss';

.page-not-found {
    position: absolute;
    top: 0;
    left: 0;

    &__card {
        width: 100vw;
        height: 100vh;
    }

    &__title {
        gap: 0.5rem;
        margin-top: 2rem;
    }

    .divider {
        width: 23.94044rem;
        height: 0.03125rem;
        background: $text-medium-emphasis;
    }

    .h5 {
        margin: 0rem !important;
    }


    .body5 {
        margin-top: 1rem !important;
    }

}