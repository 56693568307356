@import '../../../theme/variables.scss';

.engagement-dashboard {
    .modal-content {
        background-color: $structural-BG;
        border: 0.07rem solid $structural-stroke-100;
        width: 92vw;
        margin-left: 4rem;
        height: 93.077vh;
    }

    .modal-header {
        border-bottom: 1px solid $stroke-400;
        height: 2.8125rem;
    }

    .modal-body {
        --bs-modal-padding: 0.5rem;
    }

    &__icon {
        cursor: pointer;
    }

    .subtitle1 {
        margin-bottom: 0rem;
    }

    .body1,
    .body3 {
        margin-bottom: 0rem;
    }

    &__refresh-icon {
        width: 18px;
        height: 18px;
    }

    &__dot {
        width: 20px;
    }

    .table-skeleton > header {
        height: 1.5rem;
        border-radius: 0rem;
    }

    .table-skeleton .rdt_TableCell {
        height: 2.75rem !important;
    }
}

.modal {
    --bs-modal-width: none !important;
}
