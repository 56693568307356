@import '../../../theme/variables.scss';

.toast-message {
    .img,
    svg {
        width: 1.25rem;
        height: 1.25rem;
        color: $text-medium-emphasis;
        margin: 0.5rem;
    }

    .body1,
    .caption1 {
        margin: 0rem !important;
    }
    &__error {
        background: rgba($secondary-accent-red-4, 5%) !important;
        margin-top: 1.5rem;
    }
}
