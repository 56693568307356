@import '../../../theme/variables.scss';

.add-groups-modal {
    > .modal-dialog {
        max-width: 62.4375rem;
        > .modal-content {
            border-radius: 8px;
            border: 1px solid $structural-stroke-100;
            background: $body-color;
            box-shadow: 0px 0.375rem 1.5625rem 0px $vizualization-black;
            height: 38.4375rem;
            > .modal-header > .close-button {
                cursor: pointer;
                color: $text-high-emphasis !important;
            }
            > .modal-header {
                height: 3.4375rem;
                border-bottom: 1px solid $structural-stroke-100;
            }
            > .modal-body {
                height: calc(100% - 3.4375rem);
            }
        }
    }
    &__icon {
        cursor: pointer;
    }
}
