@import '../../../theme/variables.scss';

.data-grid-dropdown {
    &__dropdown-item {
        height: 2.5rem;
        padding: 0.5rem 1rem;
        &:hover {
            background-color: $primary-500 !important;
            color: $text-black !important;
        }
    }

    &__dropdown-item.active {
        background-color: $primary-500 !important;
        color: $text-black !important;
    }
    p {
        margin: 0 !important;
    }
}
