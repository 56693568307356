@import '../../../theme/variables.scss';

.prod-access-cont {
    width: 9.5rem;
    height: 2.5rem;
    &__dropdown {
        width: 152px;
    }
    &__btn {
        --bs-btn-border-color: none;
        &:focus {
            border: none;
        }
    }
    > .dropdown-toggle::after {
        display: none;
    }

    &__dropdown-menu-cont {
        border-radius: 4px;
        margin-top: 8px;
    }
    &__dropdown-item-cont {
        height: 28px;
        padding: 10px 10px 25px 10px;
        color: $text-high-emphasis;
        &:hover {
            background-color: $primary-500;
            color: $text-black;
        }
    }
    .subtitle3 {
        margin-bottom: 0rem;
    }
    .body2 {
        margin-bottom: 0rem;
    }
    &__add-button {
        border-radius: 0.25rem;
        border: none;
        width: 4.9375rem;
        height: 2.25;
        &:disabled {
            background-color: $structural-stroke-100 !important;
            color: $text-high-emphasis !important;
            border: none !important;
        }
    }
}
