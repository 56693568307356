@import '../../../theme/variables.scss';

.custom-actions-modal {
    .modal-header {
        border-bottom: 1px solid $structural-stroke-100;
    }
    > .modal-dialog {
        max-width: 77.5rem;
        > .modal-content {
            height: 47.75rem !important;
            > .modal-header > .close-button {
                cursor: pointer;
                color: $text-high-emphasis !important;
            }
            color: $text-high-emphasis !important;
            background-color: $body-color;
            .integrations-grid {
                overflow-x: hidden !important;
                > .row {
                    --bs-gutter-x: 1rem !important;
                }
            }
        }

        .search-container__search-input {
            width: 18.125rem !important;
            color: $text-high-emphasis;
        }
    }
}
