@import '../../../theme/variables.scss';

.engagements-accordion {
    &__link {
        cursor: pointer;
        display: inline;
        color: $primary-500;
        :hover {
            text-decoration: underline !important;
        }
    }
}
