@import '../../../theme/variables.scss';

.accordion {
    --bs-accordion-bg: none !important;
    --bs-accordion-border-color: none !important;

    .accordion-item {
        border-bottom: 1px solid $text-low-emphasis !important;
    }

    .subtitle7 {
        margin-bottom: 0rem !important;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .accordion-button {
        background-color: $text-black;
        color: $text-high-emphasis;

        &:focus {
            box-shadow: none;
        }
    }

    &__badge {
        border: 1px solid $stroke-500 !important;
        height: 24px;
        width: 117px;
    }

    .accordion-button::after {
        width: 0;
    }

    .accordion-button:not(.collapsed)::after {
        width: 0;
    }

    .accordion-button[aria-expanded='true'] {
        svg {
            transform: rotate(90deg);
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: $structural-200 !important;
    }
}
