@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.findings-prompt {
    &__header {
        height: 3.125rem;
        background-color: $background-ai;
        &__reset-icon {
            transform: rotateY(180deg) rotate(90deg);
        }
        &__icon {
            cursor: pointer;
        }
    }
    &__body {
        height: 11.875rem;
        &__child {
            flex: 1;
        }
    }
    &__prompt-list {
        overflow-y: auto;
        height: 8rem;
    }
    &__prompt {
        max-width: 66.4375rem;
        > .btn:hover {
            background-color: transparent;
            color: $primary-500;
        }
    }
    &__input {
        height: 2.5rem;
    }
    &__input:focus {
        border: 1px solid $primary-500 !important;
        border-right: none !important;
        box-shadow: none;
    }
    &__input:focus + &__btn {
        border: 1px solid $primary-500 !important;
        border-left: none !important;
    }
    &__btn:focus {
        background-color: transparent;
    }
    &__btn:hover {
        background-color: transparent;
    }
    &__btn:disabled {
        opacity: 1;
        :only-child {
            opacity: 0.7;
        }
    }
}

.prompt-suggestion {
    height: 4.375rem;
    width: 21.25rem;
    padding: 1rem;
    cursor: pointer;
}
