@import '../../../../theme/variables.scss';

.add-integration {
    .subtitle2 {
        margin-bottom: 0rem;
    }

    &__custom-tabs {
        border-bottom: 1px solid $structural-stroke-500;
        > .nav-item {
            padding-right: 1.75rem;
            > .nav-link {
                background-color: transparent !important;
                color: $text-medium-emphasis;
                border: none;
            }
            > .nav-link.active {
                border-bottom: 2px solid $primary-500;
                color: $text-high-emphasis;
            }
        }
    }
}
