@import '../../../theme/variables.scss';

.sign-in-page-container {
    padding: 0%;
    margin: 0%;
    width: 100%;
    height: 100vh;

    .sign-in-page {
        height: 552px;
        width: 954px;
        left: 193px;
        top: 193px;

        &__get-in-touch {
            padding: 0px 64px;
            width: 477px;
            height: 100%;
            gap: 36px;

            .subtitle2 {
                margin: 0%;
            }

            .body1 {
                margin: 0%;
            }
        }

        &__sign-in {
            width: 477px;
            height: 100%;

            .subtitle1 {
                margin-bottom: 8px;
            }

            .btn {
                height: 60px;
                width: 314px;
                padding: 0%;

                .icon-wrapper {
                    width: 64px;
                }

                .subtitle2 {
                    margin: 0%;
                }

                &:hover {
                    background-color: rgba($primary-500, 0.25);
                }
            }

            .sso-error {
                height: 18px;
                .body4 {
                    margin: 0%;
                }

                .btn {
                    height: 18px;
                    width: fit-content;
                    .body4 {
                        margin: 0%;
                    }
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
