@import '../../../theme/variables.scss';

.users-insights-modal {
    > .modal-dialog {
        max-width: 97vw;

        > .modal-content {
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid $structural-stroke-100;
            background: $structural-stroke-100;
            box-shadow: 0px 0.375rem 1.5625rem 0px $vizualization-black;
            min-height: 50.1875rem;

            > .modal-header > .close-button {
                cursor: pointer;
                color: $text-high-emphasis !important;
            }

            > .modal-header {
                height: 3.4375rem;
                border-bottom: 1px solid $structural-stroke-100;
            }
            > .modal-body {
                > .error-state {
                    height: 47.1875rem !important;
                }
            }
        }
    }

    .table-skeleton {
        justify-content: space-between;
        background-color: #1a161e;

        &__pagination {
            border-radius: 0;
        }

        .custom-scrollbar {
            flex: 1;
        }
    }

    &__icon {
        cursor: pointer;
    }

    &__provider_logo {
        filter: grayscale(1);
    }

    &__cont {
        height: 1.5rem;
        width: 1.5rem;
    }

    &__icon_cont {
        min-height: 25px;
    }
    &__react-svg {
        min-height: 25px;
        min-width: 25px;
    }
    &__svg-icon path {
        fill: $structural-stroke-100;
    }

    &__opacity-transparent {
        opacity: 0.3;
    }
}
