@import '../../../theme/variables.scss';

.avatar {
    padding: 0rem 1rem !important;
    gap: 0.5rem;
    color: $text-high-emphasis;

    &__icon {
        position: relative;
        display: inline-block;
        margin-right: 10px;

        &__dot {
            position: absolute;
            bottom: 20%;
            right: 2%;
            transform: translate(50%, 50%);
            border-radius: 50%;
        }

        &__inactive {
            background-color: $secondary-accent-red-2;
        }

        &__active {
            background-color: $success-green;
        }
    }

    .body5 {
        color: $primary-500;
        font-weight: 600;
    }
    .body5:hover {
        text-decoration: underline;
    }

    &__avatar-image {
        border-radius: 50%;
        overflow: hidden;
        min-width: 1.5rem;
    }

    &__avatar-with-name {
        margin: 0rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        overflow: hidden;
    }

    .body2 {
        margin-bottom: 0rem;
    }

    &__initials {
        border-radius: 50%;
        background-color: $primary-400;
        border: 1px solid $primary-400;
        color: $text-high-emphasis;
    }

    &__avatar-with-name--initials {
        border-radius: 50%;
        background-color: $primary-400;
        border: 1px solid $primary-400;
        color: $text-high-emphasis;
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        min-height: 1.5rem;
    }
}
