.outstanding {
    .form-check-input {
        box-shadow: none !important;
        border: none !important;
    }
    &__legend {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 0.125rem;
    }
}
