@import '../../../theme/variables.scss';
.expandable-content {
    &__disable {
        opacity: 0.3;
        pointer-events: none;
    }
    .dropdown .dropdown-toggle {
        border: none;
        background-color: rgba($body-color, 0.6);
    }
    &__button {
        padding-left: 0rem !important;
        width: fit-content;
    }
}
