@import './variables.scss';

.custom-scrollbar {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: $structural-stroke-200 $structural-stroke-500;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color: $structural-stroke-500 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: $structural-stroke-200 !important;
    border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: $structural-stroke-200 !important;
}
