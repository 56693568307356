@import '../../../theme/variables.scss';

.radar-chart {
    margin-top: -4rem;
    margin-bottom: 1.26rem;

    &__canvas-container {
        width: 12rem;
        height: 12rem;
        margin-top: -14.43rem;
        margin-left: 5.25rem;
    }

    .subtitle1 {
        margin: 0rem !important;
    }

    &__labels {
        width: 22.43rem;
    }
}