@import '../../../theme/variables.scss';

.chip {
    &__user-score-critical {
        width: 2.5rem;
        height: 1.5rem;
        background: $secondary-accent-red-4;
        color: $text-black !important;
        border-radius: 6.25rem;
        padding: 0.125rem 0.625rem !important;
    }

    &__user-score-high {
        width: 2.5rem;
        height: 1.5rem;
        background: $secondary-accent-orange-3;
        color: $text-black !important;
        border-radius: 6.25rem;
        padding: 0.125rem 0.625rem !important;
    }

    &__user-score-medium {
        width: 2.5rem;
        height: 1.5rem;
        background: $secondary-accent-yellow-3;
        color: $text-black !important;
        border-radius: 6.25rem;
        padding: 0.125rem 0.625rem !important;
    }

    &__user-score-low-risk {
        width: 2.5rem;
        height: 1.5rem;
        background: $success-green;
        color: $text-black !important;
        border-radius: 6.25rem;
        padding: 0.125rem 0.625rem !important;
    }

    &__integrated-card-variant {
        width: fit-content;
        padding: 0.3125rem 0.625rem;
        gap: 0.625rem;
        border-radius: 1.75rem;
        border: 1px solid $text-interactive;
        background: $vizualization-integratedchip-bg;
        color: $text-interactive;
    }

    &__top-risk-issue-variant {
        color: $text-high-emphasis;
        background: $vizualization-purple-chips;
        border: 1px solid $vizualization-purple-01;
        border-radius: 0.75rem;
        flex-direction: row;
    }

    &__severity-critical {
        width: 4.4375rem;
        height: 1.5rem;
        background: $chip-red;
        border: 1px solid $chip-red;
        color: $text-black !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
        gap: 0.625rem;
    }

    &__severity-high {
        width: 2.875rem;
        height: 1.5rem;
        background: $secondary-accent-orange-3;
        color: $text-black !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
        gap: 0.625rem;
    }

    &__severity-medium {
        width: 3.9375rem;
        height: 1.5rem;
        background: $secondary-accent-yellow-3;
        color: $text-black !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
        gap: 0.625rem;
    }

    &__severity-low {
        width: 2.8125rem;
        height: 1.5rem;
        background: $success-green;
        color: $text-black !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
        gap: 0.625rem;
    }

    &__with-icon {
        background: $vizualization-gradient-chip-1 !important;
        color: $text-high-emphasis !important;
        border-radius: 0.875rem;
        border: 1px solid $vizualization-sort-purple !important;
        flex-direction: row;
        width: 6.0625rem;
        height: 1.75rem;
    }

    &__image {
        padding-right: 0.3125rem;
        padding-bottom: 0.125rem;
    }

    &__employee-risk {
        width: 6.25rem;
        height: 1.75rem;
        background: $vizualization-red-chip;
        border-radius: 1.75rem;
        border: 1px solid $chip-red;
        color: $chip-red !important;
        flex-direction: row;
        padding: 0.4375rem 0.625rem !important;
        gap: 0.5rem;
    }

    &__severity-resolved-critical {
        width: fit-content;
        height: 1.5rem;
        background: $vizualization-red-300;
        border: 1px solid $chip-red;
        color: $chip-red !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
    }

    &__severity-resolved-high {
        width: fit-content;
        height: 1.5rem;
        background: $vizualization-orange-300;
        border: 1px solid $secondary-accent-orange-3;
        color: $secondary-accent-orange-3 !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
    }

    &__severity-resolved-medium {
        width: fit-content;
        height: 1.5rem;
        background: $vizualization-yellow-300;
        border: 1px solid $secondary-accent-yellow-3;
        color: $secondary-accent-yellow-3 !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
    }

    &__severity-resolved-low {
        width: fit-content;
        height: 1.5rem;
        background: $vizualization-green;
        border: 1px solid $success-green;
        color: $success-green !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
    }

    &__severity-resolved-info {
        width: fit-content;
        height: 1.5rem;
        background: $vizualization-purple-02;
        border: 1px solid $primary-500;
        color: $primary-500 !important;
        border-radius: 1.75rem;
        flex-direction: row;
        padding: 0.3125rem 0.625rem !important;
    }

    &__user-cohort-variant {
        width: fit-content;
        height: 1.375rem;
        background: $vizualization-blue-03;
        border: 0.5px solid $vizualization-blue-02;
        color: $text-high-emphasis !important;
        border-radius: 1.75rem;
        padding: 2px 10px 2px 10px;
    }

    &__status-active {
        width: 3.5rem;
        height: 1.375rem;
        background: $vizualization-green;
        border: 0.5px solid $success-green;
        color: $success-green !important;
        border-radius: 1.75rem;
        padding: 2px 10px 2px 10px;
    }

    &__status-paused {
        width: 3.5rem;
        height: 1.375rem;
        background: $vizualization-orange;
        border: 0.5px solid $secondary-accent-orange-3;
        color: $secondary-accent-orange-3 !important;
        border-radius: 1.75rem;
        padding: 2px 10px 2px 10px;
    }

    &__status-ghost {
        width: 3.5rem;
        height: 1.375rem;
        border: 0.5px solid $text-low-emphasis;
        color: $text-low-emphasis !important;
        border-radius: 1.75rem;
        padding: 2px 10px 2px 10px;
    }

    &__status-invited {
        height: 1.375rem;
        border: 1px solid $secondary-accent-yellow-3;
        color: $secondary-accent-yellow-3 !important;
        border-radius: 1.75rem;
        padding: 2px 10px 2px 10px;
    }

    &__status-suspended {
        height: 1.375rem;
        border: 1px solid $secondary-accent-red-4;
        color: $secondary-accent-red-4 !important;
        border-radius: 1.75rem;
        padding: 2px 10px 2px 10px;
    }

    &__status-error {
        width: 3.5rem;
        height: 1.375rem;
        border: 0.5px solid $secondary-accent-red-1;
        color: $secondary-accent-red-1 !important;
        border-radius: 1.75rem;
        padding: 2px 10px 2px 10px;
    }
}
