.engagement-card {
    height: 18.45vh !important;
    width: 29.75vw !important;
    &__styled-container {
        margin-top: 1.6875rem;
    }
    &__row {
        gap: 2rem;
    }

    &__column {
        gap: 0.25rem;
    }

    .body1,
    .h3 {
        margin-bottom: 0rem !important;
    }
}
