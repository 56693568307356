@import '../../../theme/variables.scss';

.dropdown {
    &__dropdown-icon {
        min-width: 1rem;
        min-height: 1rem;
        transition: transform 0.3s ease;
        color: $text-high-emphasis;
    }

    &__link-text {
        text-decoration: underline;
    }

    .dropdown-toggle {
        min-height: 1.85rem;
        border: 0.5px solid $text-low-emphasis;
        border-radius: 0.25rem;

        &::after {
            display: none !important;
        }

        &[aria-expanded='true'] {
            border-color: $text-high-emphasis;
            border-radius: 0.25rem 0.25rem 0rem 0rem;

            .dropdown__dropdown-icon {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:checked {
            border-color: $text-medium-emphasis;
        }
    }

    &__custom-menu {
        min-width: fit-content;
    }

    &__scrollable-cont {
        max-height: 10rem;
        overflow: auto;
    }

    &.default {
        .dropdown-toggle {
            border: none;
            background-color: $primary-500;
            color: $text-black;
            border-radius: 0.25rem;
            padding: 0.5rem 0.75rem 0.5rem 1rem;
        }

        .dropdown-menu {
            border-color: $text-medium-emphasis;
            border-radius: 0.25rem;
        }
    }

    &__menu-item {
        padding: 0rem 1rem;
        height: 2.25rem;
        color: $text-high-emphasis;
        z-index: 10;

        &:hover {
            background-color: $primary-500;
            color: $text-black;
        }
    }

    .body2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .body1,
    .body2,
    .body4,
    .body10,
    .caption1 {
        margin: 0rem !important;

        &.body1,
        &.body10 {
            margin-left: 0.15rem;
        }
    }

    &__custom-dropdown-menu {
        --bs-dropdown-link-hover-bg: $primary-500;
        --bs-dropdown-link-active-color: $text-black;
        --bs-dropdown-link-active-bg: $primary-500;
        --bs-dropdown-item-padding-x: none;
        --bs-dropdown-item-padding-y: none;
        border-radius: 0rem 0rem 0.25rem 0.25rem;
        box-shadow: 0rem 0.5rem 1.5rem 0rem $vizualization-box-shadow;
    }
    &__scrollable-cont {
        max-height: 10rem;
        overflow: auto;
    }

    .opacity {
        opacity: 0.5;
    }

    &__text-wrap {
        max-width: 7.8125rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &__option-link {
        padding-left: 0.8rem;
        padding-bottom: 0.3rem;
    }

    &__custom-width {
        width: 13.75rem !important;
    }
}
