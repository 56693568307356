@import '../../../theme/variables.scss';

.integrated-card {
    &__card-default {
        min-width: 18.125rem;
        min-height: 18.5rem;
    }

    .body8 {
        margin-top: 0.5rem;
        margin-bottom: 0.8rem;
    }

    .body5 {
        margin-bottom: 1.57rem;
    }

    .subtitle6 {
        margin: 0rem !important;
    }

    .body9 {
        margin-top: 0.5rem;
    }

    &__card-integrated {
        min-width: 18.125rem;
        min-height: 18.5rem;
    }

    &__card-header {
        height: 2.875rem;
    }

    .btn,
    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
        min-width: 9.75rem;
        min-height: 2.25rem;
    }

    &__card-integrated--inactive {
        opacity: 0.7;
    }

    &__icon {
        height: 77px;
        margin-bottom: 0.8rem;
    }
}
