@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.user-detail-page {
    min-width: 74.75rem;

    &__content {
        width: 100%;
    }

    &__error-container {
        width: 100%;
        margin-top: 0.625rem;
    }

    &__row {
        --bs-gutter-x: 1rem !important;
    }
}
