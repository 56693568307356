@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.custom-action-wizard {
    .modal-content {
        width: 50rem;
    }

    .modal-body {
        max-height: 44.375rem;
        height: 44.375rem;
        overflow-y: auto;
    }

    &__header {
        > .btn-close {
            cursor: pointer;
        }
    }

    &__actions {
        > .btn-cancel:hover {
            background-color: transparent;
            color: $text-low-emphasis;
        }
    }
}

.action-block {
    max-height: 28rem;

    .h4,
    .body2,
    .caption4 {
        margin: 0rem !important;
    }

    .body12 {
        margin-bottom: 0.75rem !important;
    }

    .body3 {
        margin-bottom: 0rem !important;
    }

    .dropdown-toggle {
        height: 2.5rem !important;
    }

    &__form-input {
        .input-field__input {
            height: 2.5rem !important;
        }
    }

    .text-area__form {
        width: 100%;
        margin-top: 0.38rem;
        margin-bottom: 0rem !important;
        color: $primary;
        border: 1px solid $text-low-emphasis;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border: 1px solid $text-interactive !important;
            box-shadow: none !important;

            &:focus {
                background-color: transparent;
            }
        }
    }

    &__icon {
        cursor: pointer;
    }

    &__custom-table-input {
        .input-field__input {
            min-width: 14rem !important;
            border: none;
            padding: 0rem;
            margin-top: -0.5rem !important;

            &:hover,
            &:active,
            &:focus {
                border: none !important;
            }
        }
    }

    &__action-table {
        table {
            border: 1px solid $text-low-emphasis;
            border-collapse: separate;
            border-left: 0;
            border-right: 0;
            border-radius: 0.25rem;
            border-spacing: 0rem;
            margin-bottom: 0.5rem !important;
        }

        tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
        }

        th,
        td {
            text-align: left;
            vertical-align: top;
            border-left: 1px solid $text-low-emphasis;
            border-right: 1px solid $text-low-emphasis;
        }

        td {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
            border-top: 1px solid $text-low-emphasis;
        }

        thead:first-child tr:first-child th:first-child,
        tbody:first-child tr:first-child td:first-child {
            border-radius: 0.25rem 0 0 0;
        }

        thead:last-child tr:last-child th:first-child,
        tbody:last-child tr:last-child td:first-child {
            border-radius: 0 0 0 0.25rem;
        }
    }

    &__text-center {
        height: 2.5rem;
    }

    &__custom-table {
        border-radius: 0.25rem;
    }
}
