@import '../../../theme/variables.scss';

.mfacoverage-card {
    .subtitle1 {
        margin: 0;
        margin-bottom: 7px;
    }

    &__mfacoverage-style {
        min-width: 257px;
    }

    &__error-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: '10px';
        align-items: 'center';
    }

    &__alert-icon {
        color: $secondary-accent-red-2;
    }

    &__icon {
        cursor: pointer;
        color: $text-low-emphasis;
        position: absolute;
        top: 7%;
        right: 2%;
        transform: translateY(-50%);
    }
}
