@import '../../../theme/variables.scss';

.department {
    &__progress-bar {
        min-height: 21.5rem;
        padding: 2rem;
    }
    .h4,
    .h6 {
        margin-bottom: 0rem;
    }
    &__department-container {
        overflow-y: hidden !important;
        overflow-x: auto;
        max-width: 70vw;
        min-width: 70vw;
    }
    &__header-cont {
        width: 30%;
    }

    &__menu-item {
        color: $text-high-emphasis;
        text-transform: uppercase;
        &:hover {
            background-color: $primary-500;
            color: $text-black;
        }
    }
}
