@import '../../../theme/variables.scss';

.vulnerabilities {
    &__vulnerability-row {
        margin-bottom: 1.1rem;
        --bs-gutter-x: 0%;
    }

    &__error-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__alert-icon {
        color: $secondary-accent-red-2;
    }
    &__title {
        padding-left: 8.75rem;
    }
    &__icon {
        cursor: pointer;
        color: $text-low-emphasis;
        position: absolute;
        top: 7%;
        right: 2%;
        transform: translateY(-50%);
    }
}
