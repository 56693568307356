@import '../../../theme/variables.scss';

.mfa-coverage {
    position: relative;
    height: 135px;
    width: 100%;
    max-width: 257px;
    border-radius: 8px;

    &__protected {
        position: absolute;
        background: $Viz-MFA-chip-bg;
        height: inherit;
        border-radius: 8px 0 0 8px;
        z-index: 2;
    }

    &__not-protected {
        position: absolute;
        background: $Viz-MFA-purple-1;
        height: inherit;
        width: inherit;
        border-radius: 8px;
        z-index: 1;
    }

    &__content {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: inherit;
        width: inherit;
        z-index: 3;
    }

    &__number {
        color: $secondary-accent-red-2;
    }

    &__text {
        text-transform: uppercase;
        color: $text-high-emphasis;
    }
}
