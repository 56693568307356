@import '../../../theme/variables.scss';

.preview-engagement {
    height: 91.523vh;
    min-width: 384px;
    width: 30vw;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem 0.5rem 1rem;

    &__input {
        height: 2.5rem;
    }

    &__input:focus {
        border: 1px solid $primary-500 !important;
        border-right: none !important;
        box-shadow: none;
    }

    &__input:focus+&__btn {
        border: 1px solid $primary-500 !important;
        border-left: none !important;
    }

    &__btn:focus {
        background-color: transparent;
    }

    &__btn:hover {
        background-color: transparent;
    }

    &__btn:disabled {
        opacity: 1;

        :only-child {
            opacity: 0.7;
        }
    }
}