@import '../../../theme/variables.scss';

.expandable-engagement {
    --accordion-header-width: 43.6rem;
    --bulge-size: 0.99rem;

    &__accordion-header {
        min-width: var(--accordion-header-width);
        min-height: 4.18rem;
        cursor: pointer;
        color: $text-high-emphasis;
        padding: 1rem 1rem 1rem 1rem;
        position: relative;
    }

    &__accordion-content {
        transition: height 0.3s ease;
        width: 100%;
        height: fit-content !important;
        overflow: visible;
        margin: 0;
        padding: 0;
    }

    &__bulge {
        z-index: 100;
        border-left: var(--bulge-size) solid transparent;
        border-right: var(--bulge-size) solid transparent;
        border-bottom: var(--bulge-size) solid transparent;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);

        &.vizualization-blue {
            border-bottom-color: $vizualization-blue;
        }

        &.vizualization-green-01 {
            border-bottom-color: $vizualization-green-01;
        }

        &.vizualization-purple {
            border-bottom-color: $vizualization-purple;
        }
    }

    .expandable-engagement.open &__accordion-header {
        min-height: auto;
        padding: 1rem;
    }

    .expandable-engagement.open &__accordion-content {
        height: auto;
        padding: 0rem 1rem 0.75rem 1rem;
    }

    .expandable-engagement.open &__bulge {
        &.vizualization-blue {
            border-bottom-color: $vizualization-blue;
        }

        &.vizualization-green-01 {
            border-bottom-color: $vizualization-green-01;
        }

        &.vizualization-purple {
            border-bottom-color: $vizualization-purple;
        }
    }

    .h6,
    .body1 {
        margin: 0rem !important;
    }

    .body5 {
        margin-bottom: 0rem !important;
    }
}