.iminent {
    &__iminent-container {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
    }

    &__iminent-banner {
        width: 100%;
        text-align: center;

        .h1 {
            margin-top: 0rem !important;
            margin-bottom: 0.75rem;
        }

        .body2 {
            margin: 0.2em !important;
        }
    }

    &__iminent-image {
        margin-right: 4.75rem;
        margin-bottom: 1.5rem;
    }

    &__iminent-vessel {
        width: 100%;
        height: 100%;
    }

    &__iminent-body {
        width: 100%;
        height: 100%;

    }
}