@import '../../../theme/variables.scss';

.text-area {
    &__form {
        height: 10.875rem !important;
        width: 32.125rem;
        padding: 14px 4px 0px 16px;
        border-radius: 4px;
        resize: none;
    }

    &__save-button {
        border-radius: 4px !important;
    }

    &__cancel-button {
        border-radius: 4px !important;
        border: 1px solid $text-low-emphasis !important;
    }
    &__json-style {
        white-space: pre-wrap !important;
        font-family: monospace !important;
    }
}
