@import '../../../theme/variables.scss';

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.75rem;

    .spinner-border {
        border-right-color: $text-medium-emphasis;
        color: $text-interactive;
    }
}
