@import '../../../theme/variables.scss';

.statistics-card {
    height: 33vh;
    width: 29.75vw;

    &__header {
        height: 7.844rem;
    }
}
