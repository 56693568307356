@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.auto-complete {
    .body3 {
        margin-bottom: 0.5rem !important;
    }

    .body4 {
        margin: 0rem !important;
    }

    &__menu-item {
        padding: 0rem 1rem;
        height: 2.25rem;
        color: $text-high-emphasis;

        &:hover {
            background-color: $primary-500;
            color: $text-black;
        }
    }

    &__chip {
        margin-top: 0.23rem;
        margin-left: 0.5rem;
        margin-right: 8px;
    }

    .chip__image {
        padding-bottom: 0rem !important;
    }

    .dropdown-menu {
        overflow-y: auto;
        height: auto;
        max-height: 12rem;
    }

    &__search-text {
        margin-top: -1.8rem;
        color: $text-high-emphasis;
        margin-right: 8px;
        display: inline-block;
        border: none !important;
    }

    &__chips-container {
        display: flex;
        flex-wrap: wrap;
    }
    &__toggle {
        min-height: 1.875rem;
        max-height: 3.4rem;
        overflow-y: auto;
        width: 100%;
    }
    &__expanded-container {
        cursor: pointer;
    }
}
