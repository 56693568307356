@import '../../../theme/variables.scss';

.integration-header {
    min-height: 8rem;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    &__column {
        flex: 1;
        margin-left: 1rem;
    }

    &__button-container {
        margin-right: 1.125rem;

        .btn,
        .btn-check:checked + .btn,
        :not(.btn-check) + .btn:active,
        .btn:first-child:active,
        .btn.active,
        .btn.show {
            background-color: transparent !important;
            border: 1px solid $structural-selected !important;
            color: $structural-selected !important;
            width: 6.56rem;
            height: 2.25rem;
            border-radius: 0.25rem;
        }
    }

    .body5,
    .subtitle3 {
        margin: 0rem !important;
    }

    &__circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-bottom: 0.6rem !important;
    }

    &__icon {
        height: 6rem;
    }

    .form-switch .form-check-input:checked {
        background-color: $primary-500 !important;
    }

    .form-switch .form-check-input {
        width: 2.1875rem !important;
        height: 1.25rem !important;
        box-shadow: none;
        border: none;
        background-color: $text-medium-emphasis;
    }
}
