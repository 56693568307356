@import '../../../theme/variables.scss';

.security-health {
    overflow: hidden;

    &__styled-label {
        margin-bottom: 1.5625rem;
    }

    &__icon-label-container {
        display: inline-flex;
    }

    .subtitle1 {
        margin-bottom: 0.25rem !important;
    }
}