.back-button {
    min-width: 1.9375rem;

    .body2 {
        margin: 0rem !important;
    }

    &__button {
        margin-top: 1rem;
        gap: 0.625rem;
    }

}