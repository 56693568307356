@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.data-grid {
    .body5 {
        margin: 0%;
    }

    .provider_logo {
        width: 2em;
        height: 2rem;
        padding: 0.25rem;
    }

    &__link {
        color: $primary-500;
        :hover {
            text-decoration: underline !important;
            color: $primary-500;
        }
        width: 100%;
        text-overflow: ellipsis;
    }
    .opacity-transparent {
        opacity: 0.3;
    }

    .department-row {
        max-width: 10rem;
    }

    .opacity-1 {
        opacity: 1;
    }

    &__flow-modal {
        margin: 2rem 0 -2rem 0;
    }
}
