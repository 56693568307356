@import '../../../theme/variables.scss';

.engage-modal {
    .modal-content {
        height: 24.6875rem !important;
        border-radius: 0.5rem;
        border: 0.07rem solid $structural-stroke-100;
        background: $body-color;
        box-shadow: 0px 0.375rem 1.5625rem 0px $vizualization-black;
    }

    .modal-dialog {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 37.75rem;
        height: 100vh;
        margin: auto;
    }

    .modal-header {
        border-bottom: 0.07rem solid $structural-stroke-100;
    }

    .modal-body {
        padding-bottom: 0rem;
    }

    .h4 {
        margin-bottom: 0rem;
    }

    .body5 {
        margin-bottom: 0rem;
        padding-left: 0.25rem;
    }

    &__button {
        border-radius: 0.25rem;
        height: 2.25rem;
        padding: 0.5rem 0.875rem;
    }

    &__form {
        height: 10.875rem !important;
        padding: 0.875rem 0.25rem 0rem 1rem;
        border-radius: 0.25rem;
        resize: none;
    }

    .form-check-input {
        margin-left: 0.1rem;
        margin-right: 0.5rem;
        background-color: transparent !important;
        border: 0.032rem solid $text-high-emphasis;
        box-shadow: none !important;
    }

    .form-check-label {
        padding-top: 0.1rem;
    }

    &__switch .form-check-input:checked {
        background-color: $primary-500 !important;
    }

    &__switch .form-check-input {
        box-shadow: none;
        border: none;
        background-color: $text-medium-emphasis !important;
    }

    &__dropdown {
        width: 9.6rem;
    }

    &__icon {
        cursor: pointer;
    }
}
