@import '../../../theme/variables.scss';

.dropdown-toggle.no-after-content::after {
    content: none;
}

.dropend .dropdown-toggle.no-after-content::after {
    content: none;
}

.menu {
    &__filter-button {
        padding: 0.5rem 1rem 0.5rem 1rem !important;
        gap: 0.25rem !important;

        width: 5.1875rem;
        height: 2.25rem;

        border: 1px solid $primary-500 !important;
        border-radius: 0.25rem 0rem 0rem 0.25rem;
    }

    &__filter-button-without-actions {
        padding: 0.5rem 1rem 0.5rem 1rem !important;
        gap: 0.25rem !important;

        width: 5.1875rem;
        height: 2.25rem;

        border: 1px solid $primary-500 !important;
        border-radius: 0.25rem !important;
    }

    &__arrow {
        width: 2.375rem;
        height: 2.25rem;
        border-radius: 0rem 0.25rem 0.25rem 0rem;
        border: 1px solid $primary-500 !important;
        color: $primary-500 !important;
        position: relative;
        cursor: pointer;
        margin-right: 0.25rem;
        border-left: none !important;
    }

    &__dropdown-item {
        color: $text-high-emphasis;
        padding-bottom: 0.6875rem;
        padding-top: 0.6875rem;
        height: 2.5rem;
        padding-left: 1rem;

        &:hover {
            background-color: $primary-500 !important;
            color: $text-black;
        }
    }

    &__dropdown-filter-item {
        width: 15.625rem;
    }

    &__arrow-item {
        color: $text-high-emphasis;
        height: 2.5rem;
        padding-left: 1rem;
        padding-bottom: 0.6875rem;
        padding-top: 0.6875rem;

        &:hover {
            background-color: $primary-500;
            color: $text-black;
        }
        cursor: pointer;
    }

    > ul.dropdown-menu > .disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .dropdown-menu {
        border: 0.5px solid $structural-stroke-100;
        width: 15.625rem;
    }

    > .btn.dropdown-toggle {
        &:focus {
            background-color: $structural-stroke-100 !important;
        }
    }

    .dropend:hover > .dropdown-menu,
    .dropend:active > .dropdown-menu {
        display: block;
    }

    .dropend:hover > .dropdown-toggle {
        background-color: $primary-500 !important;
        color: $text-black !important;
    }

    &__sub-label {
        color: $text-high-emphasis;
    }

    &__submenu-style {
        &:hover {
            background-color: $text-interactive;

            .form-label {
                color: $text-black;
            }

            .custom-checkbox {
                border: 1px solid $text-black !important;
            }
        }

        height: 2.5rem;
    }

    &__submenu-content {
        height: 11.875rem;
        overflow-y: auto;
    }

    &__submenu {
        height: 17.5rem;
        left: 100%;
        top: 0;
        margin-left: 0.125rem;
    }

    &__count {
        color: $text-black !important;
        border-radius: 60%;
        min-width: 0.875rem;
        height: 0.875rem;
        margin-top: 0.125rem;
        margin-left: 0.3125rem;
        margin-bottom: 1rem;
    }

    &__submenu-style > .custom-checkbox {
        display: inline-flex;
        appearance: none;
        align-items: center;
        justify-content: center;
        width: 1.125rem;
        height: 1.125rem;
        border: 1px solid $structural-stroke-600;
        border-radius: 0.1875rem;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
    }

    .custom-checkbox:checked {
        background-color: $text-interactive;
    }

    &__clear-button.btn:disabled {
        color: $structural-stroke-100 !important;
    }

    &__apply-button.btn:disabled {
        background-color: $structural-stroke-100 !important;
        color: $text-low-emphasis;
    }

    &__clear-button {
        width: 3.9375rem;
        height: 1.625rem;
    }

    &__apply-button {
        width: 3.9375rem;
        height: 1.625rem;
    }

    &__buttons {
        height: 3.75rem;
    }
}
