@import '../../../theme/variables.scss';

.active-engagements-modal {
    > .modal-dialog {
        max-width: 97vw;
    }
    &__header {
        background-color: $vizualization-zebra-row-purple !important;
    }

    &__body {
        background-color: $structural-CardBG !important;
    }
}
