@import '../../../theme/variables.scss';

.side-panel {
    min-width: 384px;
    width: 30vw;
    height: 91.523vh;
    border-radius: 0.5rem;
    &__draggable {
        border-radius: 3.125rem;
        padding: 0.375rem 1.25rem 0.375rem 1rem;
        width: fit-content;
        max-width: 16.6875rem;
        cursor: pointer;
        margin-bottom: 1rem !important;
    }
    &__text-area {
        resize: none;
        height: 6.5rem !important;
    }
    &__hr {
        border: 0.1px solid $structural-stroke-200;
        width: 100%;
        height: 1px;
    }
    &__custom-message {
        border: 0.1px solid $structural-stroke-200;
    }
    &__icon {
        width: 22px;
        height: 22px;
    }

    &__action {
        max-width: 23.5rem;
    }

    &__dropdown {
        width: 190px;
    }

    &__db-text-area {
        border: 0;
        border-bottom: 1px solid $structural-stroke-200 !important;
        border-radius: 0rem;
        resize: none;
        padding: 0rem;
        height: 1rem;
    }
    &__context-area {
        border: 1px solid $structural-stroke-200 !important;
        border-radius: 0.25rem;
    }
    &__text-area-cont {
        height: 2rem !important;
    }

    &__channel-text-area {
        height: 2rem !important;
        min-height: 2.1875rem !important;
        min-width: 10rem;
    }
}
