@import '../../../theme/variables.scss';

.search-container {
    min-height: 2.25rem;

    &__search-icon {
        left: 0.640625rem;
        top: 52%;
        transform: translateY(-50%);
        width: 1.125rem;
        height: 1.125rem;
    }

    &__input-cont {
        min-width: 20.563rem;
    }

    &__search-input {
        height: 2.25rem;
        border-radius: 0.25rem;
        padding-left: 2.125rem !important;
        font-variant: 'body4';

        &::placeholder {
            font-variant: 'body4';
            color: $text-low-emphasis;
        }
    }

    .form-control {
        color: $primary;
        border: 1px solid $text-low-emphasis;

        &:hover,
        &:active,
        &:focus {
            border: 1px solid $text-interactive !important;
            box-shadow: none !important;

            &:focus {
                background-color: transparent;
            }
        }
    }
}
