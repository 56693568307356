@import '../../../theme/variables.scss';

.dashboard {
    height: 100vh;
    padding: 0px;

    &__row {
        height: 100vh;
        width: 100%;
        --bs-gutter-x: 1rem !important;
    }

    &__Col {
        padding-left: 0rem;
    }

    &__tab-row {
        padding-top: 1.25rem;
        height: 51px;
    }

    &__content-row {
        --bs-gutter-x: 1rem !important;
    }

    &__dropdown-container {
        min-width: 8.3125rem;
    }

    .dropdown.default .dropdown-menu {
        min-width: 8.3125rem !important;
    }

}