@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.main-template {
    height: 100vh;
    &__row {
        height: 100vh;
    }
    &__Col {
        padding-left: 0rem;
        height: 100%;
    }
    &__tab-row {
        padding-top: 1.625rem;
    }
}
