@import '../../../theme/variables.scss';

.card {
    border-radius: 0.5rem;

    &__content {
        color: $text-high-emphasis;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: auto;
    }

    &__title {
        color: $text-high-emphasis;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
    }

    &__subtitle {
        color: $text-medium-emphasis;
        align-items: center;
        justify-content: center;
    }

    &--square {
        background-color: $structural-CardBG !important;
        height: 100%;
        min-height: 13.875rem;
        width: 100%;
        min-width: 24rem;
    }

    &--rectangle {
        padding: 0 3.625rem;
        background: $structural-CardBG !important;
        height: 100%;
        min-height: 13.875rem;
        width: 100%;
        min-width: 48.75rem;
    }

    &__cursor-pointer {
        cursor: pointer;
    }

    &--square .card__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--rectangle .card__body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-text:last-child {
        margin-bottom: 0;
        width: 100%;
    }

    &--custom {
        background-color: $structural-CardBG !important;
        height: 100%;
        width: 100%;
        .h5 {
            margin-bottom: 0rem;
        }
    }
}
