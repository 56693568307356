@import '../../../theme/variables.scss';

.credentials-screen-modal {
    &__content {
        margin-left: 18.125rem;
    }

    &__icon {
        cursor: pointer;
    }

    .modal-dialog {
        max-width: 77.5rem;
        display: flex;
        justify-content: center;
    }

    .modal-content {
        width: 92.5373vw;
        background-color: $body-color;
        border: 0.07rem solid $structural-stroke-100;
        border-radius: inherit;
        min-height: 20.8125rem;
    }

    .modal-header {
        border-bottom: 1px solid $structural-stroke-500;
    }

    .body11,
    .body1,
    .h4 {
        margin-bottom: 0rem;
    }

    &__button {
        border-radius: 0.25rem;
        padding: 0.5rem 2.5rem;
        height: 2.25rem;
    }
}