@import '../../../theme/variables.scss';

.user-line-graph-card {

    &__error-container {
        margin-top: '10px';
    }
    &__alert-icon {
        color: $secondary-accent-red-2;
    }

    .apexcharts-legend-marker{
        margin-top: 2px;
    }
}
