@import '../../../theme/variables.scss';

.add-integration {
    .modal-content {
        width: 92.5373vw;
        background-color: $body-color;
        border: 0.07rem solid $structural-stroke-100;
    }

    .modal-dialog {
        max-width: 77.5rem;
        display: flex;
        justify-content: center;
    }

    .modal-header {
        border-bottom: 1px solid $structural-stroke-500;
    }

    &__icon {
        cursor: pointer;
    }

    &__content {
        min-height: 44.31rem;
    }

    &__body-content {
        height: 44.31rem;
        max-width: 51.5rem;

        img {
            max-width: 100%;
        }

        overflow: scroll;
    }

    &__tab-cont {
        margin-top: 1rem;
    }

    &__button {
        height: 2.25rem;
        width: 11.375rem;
        border-radius: 0.25rem !important;

        &:hover {
            background-color: $primary-500;
        }
    }

    .body1,
    .h4 {
        margin-bottom: 0rem;
    }

    .subtitle2 {
        margin-bottom: 0rem;
    }

    .subtitle4 {
        margin-top: 0.5rem !important;
    }

    &__custom-tabs>.nav-item {
        padding-right: 1.75rem;
    }

    &__custom-tabs>.nav-item>.nav-link.active {
        border-bottom: 2px solid $primary-500;
    }

    .nav-tabs>.nav-item>.nav-link {
        background-color: transparent !important;
        color: $text-medium-emphasis;
    }

    .nav-tabs .nav-link {
        border: 0px solid transparent;
    }

    .nav-tabs .nav-item .nav-link.active {
        color: $text-high-emphasis;
    }

    .nav-tabs {
        position: relative;
        border-bottom: 1px solid $structural-stroke-500;
    }
}
