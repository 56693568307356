@import '../../../theme/variables.scss';

.invite-users {
    > .modal-dialog {
        max-width: 38rem;

        > .modal-content {
            border-radius: 0.5rem;
            border: 1px solid $structural-stroke-100;
            background: $body-color;
            box-shadow: 0rem 0.375rem 1.5625rem 0rem $vizualization-black;
            height: 27.625rem;

            > .modal-header > .close-button {
                cursor: pointer;
                color: $text-high-emphasis !important;
            }

            > .modal-header {
                height: 3.4375rem;
                border-bottom: 0.0625rem solid $structural-stroke-100;
            }

            > .modal-body {
                height: calc(100% - 3.4375rem);
            }
        }
    }

    &__icon {
        cursor: pointer;
    }

    &__button-cont {
        background-color: $structural-selected !important;
        border: 0.0625rem solid $structural-selected !important;
        color: $structural-selected !important;
        width: 6.125rem;
        height: 2.25rem;
        border-radius: 0.25rem;
        padding: 0rem;
    }

    .body1 {
        margin: 0rem !important;
    }

    .search-container .form-control {
        min-width: 23.5rem;
    }

    &__user-details {
        height: 18rem;
        gap: 1.45rem;
    }
}
