//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import 'variables';
@import 'bootstrap';

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    .h0 {
        font-size: 3.75rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 4.5rem;
    }

    .h1 {
        font-size: 3.125rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 3.75rem;
    }

    .h2 {
        font-size: 3.125rem;
        font-weight: 400;
        font-family: 'Barlow';
        line-height: 3.75rem;
    }

    .h3 {
        font-size: 2.75rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 3.3125rem;
    }

    .h4 {
        font-size: 1.1875rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 0.875rem;
    }

    .h5 {
        font-size: 3.125rem;
        font-family: 'Barlow';
        font-weight: 700;
        line-height: normal;
    }

    .h6 {
        font-size: 1.125rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 0.875rem;
    }

    .subtitle0 {
        font-size: 2rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 2.4rem;
    }

    .subtitle1 {
        font-size: 1.125rem;
        font-weight: 600;
        font-family: 'Barlow';
        line-height: 0.875rem;
    }

    .subtitle2 {
        font-size: 0.9375rem;
        font-weight: 800;
        font-family: 'Barlow';
        line-height: 1.125rem;
    }

    .subtitle3 {
        font-size: 0.9375rem;
        font-weight: 400;
        font-family: 'Barlow';
        line-height: 0.875rem;
    }

    .subtitle4 {
        font-size: 1.125rem;
        font-weight: 600;
        font-family: 'Barlow';
        line-height: 1.35rem;
    }

    .subtitle5 {
        font-size: 1.75rem;
        font-family: 'Barlow';
        font-weight: 500;
        line-height: normal;
    }

    .subtitle6 {
        font-size: 1rem;
        font-weight: 600;
        font-family: 'Barlow';
        line-height: normal;
    }

    .subtitle7 {
        font-size: 1.125rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 1.35rem;
    }

    .body1 {
        font-size: 0.9375rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 1.125rem;
    }

    .body2 {
        font-size: 0.9375rem;
        font-weight: 600;
        font-family: 'Barlow';
        line-height: 1.25rem;
    }

    .body3 {
        font-size: 0.875rem;
        font-weight: 400;
        font-family: 'Barlow';
        line-height: 1.0625rem;
    }

    .body4 {
        font-size: 0.875rem;
        font-weight: 600;
        font-family: 'Barlow';
        line-height: 1.0625rem;
    }

    .body5 {
        font-size: 0.9375rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 1.125rem;
    }

    .body6 {
        font-size: 0.8125rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 0.875rem;
    }

    .body7 {
        font-size: 0.875rem;
        font-family: 'Barlow';
        font-weight: 500;
        line-height: normal;
    }

    .body8 {
        font-size: 1.125rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: normal;
    }

    .body9 {
        font-size: 1.75rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: normal;
    }

    .body10 {
        font-size: 0.875rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: normal;
    }

    .body11 {
        font-size: 1.375rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 0.875rem;
    }

    .body12 {
        font-size: 1.375rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: normal;
    }

    .caption1 {
        font-size: 0.875rem;
        font-weight: 400;
        font-family: 'Barlow';
        line-height: 1.0625rem;
    }

    .caption2 {
        font-size: 0.8125rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 1.5rem;
    }

    .caption3 {
        font-size: 0.8125rem;
        font-weight: 800;
        font-family: 'Barlow';
        line-height: 0.875rem;
        letter-spacing: 0.05rem;
    }

    .caption4 {
        font-size: 0.75rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 1rem;
        letter-spacing: 0rem;
    }

    .caption5 {
        font-size: 0.75rem;
        font-weight: 700;
        font-family: 'Barlow';
        line-height: 0.9rem;
        letter-spacing: 0.01em;
    }

    .overline {
        font-size: 0.6875rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: normal;
    }

    .overline1 {
        font-size: 0.6875rem;
        font-weight: 500;
        font-family: 'Barlow';
        line-height: 0.825rem;
    }

    .overline2 {
        font-size: 0.75rem;
        font-weight: 600;
        font-family: 'Barlow';
        line-height: 0.9rem;
    }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------
