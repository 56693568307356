@import '../../../theme/variables.scss';

.add-user {
    .subtitle3 {
        margin-bottom: 0rem;
    }
    .body2 {
        margin-bottom: 0rem;
    }
    &__add-button {
        border-radius: 0.25rem;
        border: none;
        width: 4.9375rem;
        height: 2.25;
        &:disabled {
            background-color: $structural-stroke-100 !important;
            color: $text-high-emphasis !important;
            border: none !important;
        }
    }

    .table-skeleton {
        min-width: 62.3rem !important;
    }
}
