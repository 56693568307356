.tooling-coverage-page {
    &__body {
        min-width: 74.75rem;
        height: calc(100% - 1.875rem);
        .rdt_Table {
            min-height: 80vh;
        }
        .table-skeleton__pagination {
            border-radius: 0;
        }
    }
}
