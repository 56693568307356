.top-risk-contributors {
    .caption1 {
        margin-bottom: 0% !important;
    }

    &__error-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: 'center';
    }
}