@import '../../../theme/variables.scss';

.speedometer {
    overflow: hidden;
    height: 13.92rem;

    &__speedometer-container {
        position: relative;
        overflow: hidden;
        height: 20.5rem;
        margin-top: -2rem;
    }

    &__speedometer-needle {
        margin-top: -7.125rem;
        margin-left: 2.5rem;
    }

    &__speedometer-image {
        margin-top: 20.5rem;
    }

    .container {
        width: 9.375rem;
        background: #1A161E;
        border: 1px solid rgba(221, 203, 239, 0.25);
        border-radius: 0.5rem;
        position: relative;
        margin-top: -27.19rem;
        padding-bottom: 0.63rem;
        z-index: 1;
    }

    &__content {

        .h0,
        .subtitle1 {
            margin: 0px !important;
        }
    }

    &__health-metrics-row {
        gap: 0.5rem;

        .subtitle0,
        .body6 {
            margin: 0px !important;
        }
    }

    &__health-metrics-cell {
        flex: 1;
        padding: 0.5rem;
    }

    &__score {
        width: 6.25rem;
        gap: 0.125rem;
        margin-top: -45.5rem;
    }
}