@import '../../../theme/variables.scss';

.linegraph {
    .apexcharts-canvas {
        background-color: $text-black !important;
        min-width: 19.4vw !important;
    }

    .apexcharts-tooltip-text-y-label {
        display: none !important;
    }

    .apexcharts-tooltip {
        background-color: $text-medium-emphasis !important;
        color: $text-black !important;
    }
}
