.custom-findings {
    height: calc(100% - 4.6875rem);
    .btn {
        --bs-btn-padding-x: 0rem;
        --bs-btn-active-border-color: transparent;
    }

    &__content {
        min-height: 100%;
    }
}
