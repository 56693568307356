@import '../../../theme/variables.scss';


.input-field {

    &__icon {
        right: 0.640625rem;
        width: 1rem;
        height: 1rem;
        bottom: 0.8rem;
        cursor: pointer;
        color: $text-medium-emphasis;
    }

    &__input {
        min-width: 30rem;
        min-height: 2.5rem;
        border-radius: 0.25rem;
        font-variant: 'body4';

        &::placeholder {
            font-variant: 'body4';
            color: $text-low-emphasis;
            color: $text-low-emphasis;
        }
    }

    .body3 {
        margin-bottom: 0.5rem !important;
    }

    .body4 {
        margin: 0rem !important;
    }

    .form-control {
        color: $primary;
        border: 1px solid $text-low-emphasis;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border: 1px solid $text-interactive !important;
            box-shadow: none !important;

            &:focus {
                background-color: transparent;
            }
        }
    }
}