@import '../../../theme/variables.scss';

.stepper-container {
    min-height: 41rem;
    margin-top: 1rem;

    .body1,
    .body6 {
        margin: 0rem !important;
    }

    &__circle-container {
        width: 80%;
    }

    &__circle {
        width: 2.25rem;
        height: 2.25rem;
        border-radius: 50%;
        border: 2px solid $text-low-emphasis;
    }

    &__step-text {
        width: 23%;
    }

    &__stepper-content {
        margin-top: 2.75rem;
    }

    &__line {
        flex: 1;
        height: 2px;
    }

    &__button-save {
        width: 8.75rem;
        height: 2.25rem;
        border-radius: 0.25rem !important;
    }

    &__button-navigation {
        width: 8.75rem;
        height: 2.25rem;
        border-radius: 0.25rem !important;

        &:hover,
        &:focus {
            background-color: rgba($primary-500, 0.25) !important;
            color: $primary-500 !important;
        }
    }

    &__opacity-reduced {
        opacity: 0.7;
    }

}