.info-card {
    &__container {
        width: 100%;
        min-height: 7.625rem;
        border-radius: 0.5rem;

        .body2,
        .body3 {
            margin-bottom: 0rem !important;
        }
    }

    &__item {
        margin-bottom: 0.375rem;
        > .body2 {
            margin-right: 1rem;
        }
    }

    &__text-ellipsis {
        > .body2 {
            text-overflow: ellipsis;
            width: 70%;
        }
    }

    &__styled-category {
        width: 7.5rem;
        margin-right: 0.5rem;
    }
}
