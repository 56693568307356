@import '../../../theme/variables.scss';

.engagement-card {
    &__vessel {
        gap: 0.5rem;

        .body1,
        .h3 {
            margin: 0rem !important;
        }
    }

    &__engagement-row {
        row-gap: 1.8rem;
    }

    &__error-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }

    &__icon {
        cursor: pointer;
        color: $text-low-emphasis;
        position: absolute;
        top: 7%;
        right: 2%;
        transform: translateY(-50%);
    }
}
