@import '../../../theme/variables.scss';
.icon-with-typography {
    &__row-container {
        display: flex;
        justify-content: center;
        text-align: center;
        color: $text-high-emphasis;
        gap: 8px;
    }

    &__col-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        display: flex;
        align-items: center;
        text-align: center;
        color: $text-high-emphasis;
        gap: 18px;
    }
}
