@import '../../../theme/variables.scss';
.error-state {
    .custom-typography p {
        margin-bottom: 0;
    }
    &__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__icon-row {
        display: grid;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    &__icon {
        display: flex;
        justify-content: center;
    }
    &__error-icon {
        color: $secondary-accent-red-2;
        height: 1.875rem;
    }
    .btn {
        --bs-btn-padding-x: 0px;
        --bs-btn-active-border-color: transparent;
        color: $text-interactive;
        &:focus {
            color: $text-interactive;
        }
    }
    .body5{
        margin-bottom: 0px;
    }
}
