@import '../../../theme/variables.scss';

.security-coverage-tooling {
    &__styled-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 1.6875rem;
        padding-bottom: 1.5rem;
    }

    &__styled-icons {
        height: 16%;
        width: 2.8125rem;
        border-radius: 0%;
    }

    &__icon-color-red {
        color: $secondary-accent-red-2;
    }

    &__icon-color-green {
        color: $success-green;
    }

    .h3 {
        margin-bottom: 0.625rem !important;
    }

    &__error-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: '10px';
        align-items: 'center';
    }

    &__alert-icon {
        color: $secondary-accent-red-2;
    }

    &__icon {
        cursor: pointer;
        color: $text-low-emphasis;
        position: absolute;
        top: 7%;
        right: 2%;
        transform: translateY(-50%);
    }
}
