@import '../../../theme/variables.scss';

.config-tabs {

    .body2,
    .body5 {
        margin-bottom: 0rem;
    }

    &__custom-tabs>.nav-item>.nav-link.active {
        border: none;
        border-bottom: 2px solid $primary-500 !important;
        background-color: transparent !important;
    }

    .nav-tabs>.nav-item>.nav-link {
        background-color: transparent !important;
        color: $text-medium-emphasis !important;
    }

    .nav-tabs .nav-link {
        border: none;
    }

    .nav-tabs .nav-item .nav-link.active {
        color: $text-high-emphasis !important;
    }

    .nav-tabs {
        position: relative;
        border-bottom: 1px solid $structural-stroke-500 !important;
    }
}