.icon-status {
    position: relative;
    display: inline-block;
    margin-right: 0.625rem;
    &__logo {
        position: absolute;
        top: 60%;
        left: 70%;
        width: 0.8125rem;
        height: 0.8125rem;
        border-radius: 50%;
    }
    &__secondary-logo {
        position: absolute;
        top: -10%;
        left: 70%;
        width: 0.8125rem;
        height: 0.8125rem;
        border-radius: 50%;
    }
}
