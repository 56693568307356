@import '../../../theme/variables.scss';

.user-cohort {
    &__dropdown-container {
        width: 20%;
        min-height: 1.875rem;
        max-height: 3.4rem;
    }

    .dropdown-toggle {
        min-height: 1.875rem;
        background-color: $vizualization-blue-01;
        border: none;
        border-radius: 0.25rem 0rem 0rem 0.25rem;

        &::after {
            display: none !important;
        }

        &[aria-expanded='true'] {
            border-color: none;
            border-radius: 0.25rem 0.25rem 0rem 0rem;
            border: none !important;

            .dropdown__dropdown-icon {
                transform: rotate(180deg);
            }
        }

        &:hover,
        &:checked {
            border-color: none;
            border: none !important;
        }
    }

    .auto-complete {
        width: 100%;
        background-color: $vizualization-blue-01;
        border-radius: 0rem 0.25rem 0.25rem 0rem;
        border: none !important;
        height: 1.875rem;
        > .dropdown-toggle {
            padding: 0rem;
            max-height: 3.4rem;
            min-height: 1.875rem;
        }
    }

    .input-group > .form-control {
        height: 1.875rem;
    }

    &__delete-icon {
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 1;
        }

        &.single-row {
            cursor: not-allowed;
            opacity: 0.5;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    &__more-than-five-rows {
        margin-right: 1rem;
    }

    .caption4 {
        margin-bottom: 0.5rem !important;
    }
}
