@import '../../../theme/variables.scss';

.list-group.integration-filter {
    min-width: 18.125rem;
    min-height: 18.5rem;
    padding: 0rem 0.5625rem 0.5rem 0.5625rem;

    > .list-group-item {
        border-radius: 0.25rem;
        margin-top: 0.5625rem !important;
        padding: 0.625rem;
        height: 2.375rem;
        cursor: pointer;

        > div {
            > .body2 {
                margin: 0;
            }
        }
    }
    > .list-group-item.active {
        background-color: $primary-500 !important;
        color: $body-color !important;
    }
}
