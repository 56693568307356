@import '../../../theme/variables.scss';

.tenant-selection-page {
    width: 100vw;
    height: 100vh;

    &__tenant-container {
        min-width: 59.625rem;
        min-height: 34.5rem;
        padding: 7.4375rem 17.125rem 7.4375rem 17.1875rem;
        border-radius: 0.5rem;
        position: relative;
    }

    .subtitle4 {
        margin: 0rem !important;
    }

    &__button-container {
        .btn,
        .btn-check:checked + .btn,
        :not(.btn-check) + .btn:active,
        .btn:first-child:active,
        .btn.active,
        .btn.show {
            background-color: transparent;
            border: 1px solid $structural-selected !important;
            color: $text-high-emphasis !important;
            min-width: 19.6875rem;
            min-height: 3.875rem;
            padding: 1.25rem 4rem;
            border-radius: 0.25rem;
            &:hover {
                background-color: rgba($primary-500, 0.25) !important;
            }
        }
    }
    &__back-button {
        position: absolute;
        top: 0;
        left: 0;
        margin: 1.5rem;
        cursor: pointer;
    }
    .page-loader {
        &__vessel {
            height: 100% !important;
        }
    }
}
