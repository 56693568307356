@import '../../../theme/variables.scss';

.findings-config {
    .modal-dialog {
        display: flex;
        justify-content: center;
    }
    .modal-content {
        background-color: $body-color;
        width: 37.5rem;
        border: 1px solid $structural-stroke-100;
    }
    .modal-header {
        border-bottom: 1px solid $structural-stroke-200;
    }
    &__content {
        height: 32rem;
    }
    &__form {
        height: 2.5rem;
        border: 1px solid $text-low-emphasis !important;
    }

    &_display {
        display: none !important;
    }

    &__check {
        border: 0.1px solid $structural-stroke-200;
        border-top-left-radius: 0.25rem !important;
        border-top-right-radius: 0.25rem !important;
    }
    &__description-form {
        height: 10rem !important;
        resize: none;
        border-radius: 0.25rem !important;
    }
    &__cancel-button {
        border: 1px solid $text-low-emphasis !important;
    }

    .form-switch .form-check-input {
        width: 2.1875rem !important;
        height: 1.25rem !important;
        box-shadow: none;
        border: none;
        background-color: $text-medium-emphasis;
    }
    &__table-cell {
        border: 0.1px solid $structural-stroke-200 !important;
        border-top: none !important;
        height: 2.6rem;
        padding: 0 !important;
    }
    &__input {
        height: 2.5rem;
        resize: none;
    }
    &__name {
        cursor: pointer !important;
    }
}
