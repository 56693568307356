@import '../../../theme/variables.scss';

.confirmation-modal {
    .subtitle4,
    .body5,
    .body2 {
        margin-bottom: 0rem;
    }

    .modal-content {
        background-color: $body-color !important;
        border: 0.07rem solid $structural-stroke-100;
    }

    .modal-header {
        border-bottom: 1px solid $stroke-400;
    }

    .custom-width-default {
        max-width: 28.44vw;
    }

    .custom-width-custom {
        max-width: 35.5vw;
    }

    &__engagement-cont {
        overflow-y: auto;
        max-height: 7rem;
    }

    &__icon {
        cursor: pointer;
    }

    &__cancel-button {
        border-radius: 4px;
        border: 1px solid $text-low-emphasis;

        &:hover {
            border: 1px solid $text-low-emphasis;
        }
    }

    &__primary-button {
        border-radius: 4px;
    }

    &__button {
        padding-top: 68px;
        padding-bottom: 20px;
    }

    &__children {
        padding-top: 40px;
    }
}
