@import '../../../theme/variables.scss';

.user-invited {
    padding-right: 1rem;
    padding-left: 0.5rem;

    .body3 {
        margin: 0rem !important;
    }

    .subtitle3 {
        margin-bottom: 0.5rem !important;
    }

    &__icon {
        cursor: pointer;
        color: $text-high-emphasis !important;
    }

    .avatar__initials {
        width: 1.5rem;
        height: 1.5rem;
    }

    .avatar {
        padding: 0rem !important;
    }
}
