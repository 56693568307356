@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.grid-head-filter {
    > .dropdown > .dropdown-toggle.show {
        color: $text-high-emphasis;
    }

    .data-grid-dropdown {
        width: 16.125rem;
        border: 1px solid $text-low-emphasis;

        &__dropdown-item {
            overflow-x: hidden;

            &:hover {
                background-color: $primary-500 !important;
                color: $text-black !important;
                > .form-check-input {
                    border: 1px solid $text-black !important;
                }
            }
        }

        > .data-grid-dropdown__filters {
            height: 9.5rem;
        }

        > li > .dropdown-header {
            height: 2.5rem;
        }
    }
    p {
        margin: 0;
    }
}
