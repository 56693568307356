@import '../../../theme/variables.scss';

.user-header {
    &__container {
        width: 100%;
        min-height: 7.625rem;
        border-radius: 0.5rem;

        .body2,
        .body3 {
            margin-bottom: 0rem !important;
        }
    }

    .visibility-hidden {
        display: none;
    }

    &__column {
        flex: 1;
        margin-left: 1rem;
    }

    .h4 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 17.5rem;
    }
    &__value-container {
        padding-top: 2.4375rem;
    }

    &__divider {
        width: 1px;
        height: 2.625rem;
        margin-top: 2.5rem;
    }

    &__styled-category {
        width: 7.5rem;
        margin-right: 0.5rem;
    }

    &__item {
        margin-bottom: 0.375rem;
        > .body2 {
            margin-right: 1rem;
        }
    }

    &__icon {
        height: 0.9375rem;
        width: 1rem;
    }

    &__colon {
        width: 0.625rem;
    }

    &__chip-style {
        margin-right: 0.5rem;
    }

    &__button-container {
        margin-right: 1.125rem;

        .btn,
        .btn-check:checked + .btn,
        :not(.btn-check) + .btn:active,
        .btn:first-child:active,
        .btn.active,
        .btn.show {
            display: none;
            background-color: transparent !important;
            border: 1px solid $structural-selected !important;
            color: $structural-selected !important;
            width: 6.56rem;
            height: 2.25rem;
            border-radius: 0.25rem;
            padding: 0rem;
        }
    }

    &__dropdown-icon {
        margin-left: 0.625rem;
        margin-top: 0.125rem;
    }

    &__typography {
        width: 10rem;
        height: 1.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
