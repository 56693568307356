@import '../../../theme/variables.scss';

.custom-tabs {
    display: flex;
    background-color: $structural-BG;
    gap: 3rem;
    width: 50%;
    :root {
        --structural-BG: #{$structural-BG};
    }

    .tabs-container {
        height: 25px;
        align-content: center;
        display: flex;
        gap: 3rem;
    }

    .nav {
        display: flex;
        flex-direction: row;
        height: 25px;
        align-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        gap: 2.625rem;
        border-bottom: $text-high-emphasis;
        background-color: $structural-BG;
        border: none;
        &-link {
            height: 24px;
            padding: 6px 0 0 0;
            text-transform: uppercase;
            background-color: $structural-BG;
            align-items: center;
            color: $text-medium-emphasis;
            display: flex;
            align-items: center;
            letter-spacing: 0.07em;
            border: none;
            --bs-nav-tabs-link-active-border-color: var(--structural-BG);
            --bs-nav-tabs-link-hover-border-color: var(--structural-BG);
            cursor: pointer;
            &.active {
                color: $text-high-emphasis;
                border-bottom: 0.1875rem solid $structural-selected;
            }
        }
    }
}
