@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.create-engagement-modal {
    .was-validated .form-control:invalid,
    .form-control.is-invalid {
        border: 1px solid $structural-stroke-100 !important;
        background-image: none !important;
        box-shadow: none !important;
    }

    .was-validated .form-control:valid,
    .form-control.is-valid {
        border: 1px solid $structural-stroke-100 !important;
        background-image: none !important;
        box-shadow: none !important;
    }

    &__header {
        > .btn-close {
            cursor: pointer;
        }
    }

    &__actions {
        > .btn-cancel:hover {
            background-color: transparent;
            color: $text-low-emphasis;
        }
    }

    &__body {
        &__textarea-wrapper {
            position: relative;
            &__textarea {
                resize: none;
            }

            &__count {
                position: absolute;
            }
        }
    }
}
