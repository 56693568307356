@import '../../../theme/variables.scss';

.vertical-bars {
    min-width: 5rem;
    min-height: 17rem;
    margin-bottom: 0.6rem;
    cursor: pointer;
    &__no-color {
        margin: 0 2px;
        border-radius: 2px;
        width: 1rem;
        height: 0.37rem;
        box-sizing: border-box;
    }
    .hr {
        border: 0.1px solid $structural-stroke-200;
        width: 2rem;
        height: 1px;
    }
    .subtitle2,
    .caption4,
    .caption5,
    .overline {
        margin-bottom: 0rem;
    }

    &__solo {
        border-radius: 4px;
        padding: 0.1875rem;
    }

    &__border-selected {
        width: 5rem;
    }

    &__text-style {
        max-width: 4rem;
        text-align: center;
        height: 2rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /* Number of lines to show */
    }
}
