@import '../../../theme/variables.scss';

.custom-tooltip {
    z-index: 1100;
    .tooltip-inner {
        background-color: $text-medium-emphasis !important;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    .overline2,
    .subtitle4 {
        padding-bottom: 0rem;
    }
    .tooltip-arrow {
        display: none;
    }
    &__content {
        width: fit-content;
    }
    &__label-container {
        white-space: pre-line;
        text-align: center;
    }
}
