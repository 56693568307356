@import '../../../theme/variables.scss';

.saved {
    .modal-dialog {
        display: flex;
        justify-content: flex-end;
        padding-top: 5.3125rem;
        padding-right: 4.875rem;
    }

    .modal-content {
        background-color: $body-color;
        border: 0.07rem solid $structural-stroke-100;
        width: 45rem;
    }

    .modal-body {
        padding: 20px 16px;
        max-height: 24.5rem;
        height: 24.5rem;
        overflow-y: auto;
    }

    .subtitle4,
    .body5 {
        margin-bottom: 0rem;
    }

    &__clickable {
        cursor: pointer;
    }
}