@import '../../../theme/variables.scss';

.vendors-integration {
    .body2 {
        margin-bottom: 0rem !important;
    }

    .body9 {
        margin-bottom: 1.25rem !important;
    }

    &__toast-container {
        min-width: 72.75rem;
    }

    &__plus-icon {
        margin-left: 0.625rem;
        margin-top: 0.125rem;
        color: $structural-selected;
    }

    &__button-container {
        margin-right: 1.125rem;

        .btn,
        .btn-check:checked + .btn,
        :not(.btn-check) + .btn:active,
        .btn:first-child:active,
        .btn.active,
        .btn.show {
            background-color: transparent !important;
            border: 1px solid $structural-selected !important;
            color: $structural-selected !important;
            width: 8rem;
            height: 2.25rem;
            border-radius: 0.25rem;
            padding: 0rem;
        }
    }

    &__column {
        flex: 1;
        margin-left: 1rem;
    }

    &__toast-container {
        min-width: 72.75rem;
    }

    &__plus-icon {
        margin-left: 0.625rem;
        margin-top: 0.125rem;
        color: $structural-selected;
    }

    &__title-container {
        margin-top: 1.5rem;
    }

    &__button-container {
        margin-right: 1.125rem;

        .btn,
        .btn-check:checked + .btn,
        :not(.btn-check) + .btn:active,
        .btn:first-child:active,
        .btn.active,
        .btn.show {
            background-color: transparent !important;
            border: 1px solid $structural-selected !important;
            color: $structural-selected !important;
            width: 8rem;
            height: 2.25rem;
            border-radius: 0.25rem;
            padding: 0rem;
        }
    }

    &__column {
        flex: 1;
        margin-left: 1rem;

        &__toast-container {
            min-width: 72.75rem;
        }
    }
}
