@import '../../../theme/variables.scss';
@import '../../../theme/scroll.scss';

.create-engagement-page {
    min-width: 70rem;

    .preview-mode .expandable-list,
    .preview-mode .canvas__canvas-styles {
        opacity: 0.6;
        pointer-events: none;
    }

    &__title {
        margin-top: -0.5rem;
        max-width: 74rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &__edit-icon {
            display: none;
        }
        &:active,
        &:focus {
            border: 0.1px solid $text-low-emphasis !important;
            box-shadow: none !important;
            padding-bottom: 0.2rem !important;
            padding-left: 0.2rem !important;

            &:focus {
                background-color: transparent;
            }
        }
    }

    .create-engagement-page__title:focus + .create-engagement-page__title__edit-icon,
    .form-group:hover .create-engagement-page__title__edit-icon {
        display: inline-block;
    }

    &__body {
        &__left {
            height: 91.523vh;
            border-radius: 0.5rem 0.5rem 0 0;

            &__preview-btn {
                top: 93%;
                width: 13rem;
                height: 2.25rem;
            }
        }
    }

    &__canvas-wrapper {
        height: calc(100% - 7rem);
        padding-bottom: 6rem;
    }

    &__save-btn {
        width: 5.5625rem;
        height: 1.875rem;
    }
    &__span {
        max-width: 75%;
        overflow: hidden;
        visibility: hidden;
    }
}
