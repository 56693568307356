@import '../../../theme/variables.scss';

.sideNav {
    width: min-content;
    height: 100vh;
    &__container {
        flex-direction: column;
        margin-bottom: 0.9375rem;
        padding: 0 1rem;
    }

    &__link {
        cursor: 'pointer';
        text-decoration: none;
        color: $text-medium-emphasis;
        :hover {
            color: $text-high-emphasis;
        }
    }

    &__navbar {
        width: 9rem;
        height: 100vh;
        background-color: $structural-CardBG;
        padding: 0%;
    }

    &__brand {
        margin: 1rem 0;
    }
    &__container {
        height: 100vh;
    }

    &__navbar &__container &__nav {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        height: 100vh;
    }

    &__nav &__link {
        border-radius: 0.543rem;
        color: $text-high-emphasis;
        display: flex;
        gap: 0.438rem;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        min-height: 4.875rem;
        min-width: 7rem;
        :hover {
            color: $text-high-emphasis;
        }
    }

    &__nav .nav-link.active {
        background-color: $structural-selected;
        color: $text-black;
        :hover {
            color: $text-black;
        }
        display: flex;
        justify-content: center;
    }

    &__link .caption3 {
        margin: 0;
    }

    &__svg-icon path {
        fill: $structural-selected;
    }

    .active &__svg-icon path {
        fill: $text-black;
    }

    &__user-avatar {
        align-self: flex-start;
    }

    &__user-avatar-element {
        height: 2.5rem;
        width: 2.5rem;
        color: $text-medium-emphasis;
    }

    .sign-out {
        > .dropdown-menu {
            --bs-dropdown-link-hover-bg: none;
            --bs-dropdown-link-active-bg: none;
        }

        .body5 {
            margin: 0%;
        }
        .overline {
            margin: 0%;
        }

        > .dropdown-toggle::after {
            content: none !important;
        }
        > .dropdown-toggle {
            &[aria-expanded='true'] {
                .dropdown__dropdown-icon {
                    transform: rotate(180deg);
                    transition: transform 0.3s ease;
                }
            }
        }
    }

    &__react-icon {
        color: $primary-500 !important;
        :hover {
            color: $primary-500 !important;
        }
    }

    .sideNav__link.active .sideNav__react-icon {
        color: $text-black !important;
        :hover {
            color: $text-black !important;
        }
    }
}
