@import '../../../theme/variables.scss';

.health-metrics {

    &__styled-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__row-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
    }

    &__icon-color-red {
        color: $secondary-accent-red-2
    }

    &__styled-space {
        margin-left: 0.125rem;
    }

    .h3 {
        margin: 0.125rem !important;
    }

    .body1 {
        margin: 0rem !important;
    }

    .subtitle1 {
        margin: 0rem !important;
    }

    .caption2 {
        margin: 0rem !important;
    }

    .overline {
        margin: 0rem !important;
    }

    .text-uppercase {
        margin: 0rem !important;
    }
}