.findings-expand {
    &__input {
        min-height: 75px;
        height: 75px;
        overflow-y: auto;
    }
    &__input:focus {
        box-shadow: none;
    }
}
