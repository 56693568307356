@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: 'Barlow', sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$body-color: #000000;

$primary: #e2effd !default;
$primary-100: #e2effd !default;
$primary-300: #6c2aa7 !default;
$primary-400: #8e2ae8 !default;
$primary-500: #9975ff !default;
$primary-700: #6d53b5 !default;

$chip-red: #cc3329 !default;
$background-ai: #3f355a;
$foundation-blue-200: #d0c0ff;
$text-high-emphasis: #ffffff !default;
$text-medium-emphasis: #d1cae5 !default;
$text-low-emphasis: #958fa3 !default;
$text-black: #1a161e !default;
$text-interactive: #9975ff !default;
$hover-login-cell: #3a2e56 !default;
$structural-BG: #2d2832 !default;
$structural-stroke: #635472 !default;
$structural-stroke-100: #534d66 !default;
$structural-stroke-200: #67656e !default;
$structural-stroke-300: #34313d !default;
$structural-stroke-400: #1c1825 !default;
$structural-stroke-500: #30363d !default;
$structural-stroke-600: #d8dce6 !default;
$structural-CardBG: #1a161e !default;
$structural-selected: #9975ff !default;
$structural-200: #211a33 !default;
$structural-300: #1e233f !default;
$structural-400: #1c1825 !default;
$structural-9: #bbace5 !default;
$structural-badge-bg: #2d343d !default;
$grey-50: #867399 !default;
$grey-100: #544761 !default;
$grey-300: #292c2f !default;
$grey-400: #322d40 !default;
$stroke-400: #585366 !default;
$stroke-500: #7362a3 !default;
$secondary-accent-grey-1: #2c3141 !default;
$secondary-accent-red-1: #dd3a3a !default;
$secondary-accent-red-2: #dc3b3b !default;
$secondary-accent-red-3: #ff6058 !default;
$secondary-accent-red-4: #ff2629 !default;
$success-green: #a1d730 !default;
$secondary-accent-blue-1: #ded2ff !default;
$secondary-accent-blue-2: #7c4dff !default;
$secondary-accent-blue-3: #3b5edc !default;
$secondary-accent-yellow-1: #f1df3a !default;
$secondary-accent-yellow-2: #ffc130 !default;
$secondary-accent-yellow-3: #ffff07 !default;
$secondary-accent-orange-1: #e99243 !default;
$secondary-accent-orange-2: #ff9533 !default;
$secondary-accent-orange-3: #ff8c22 !default;
$table-row-hover: #272333 !default;
$viz-sort-purple: #513a92 !default;
$viz-zebra-row-purple: #282030 !default;
$table-row-hover: #241b40 !default;
$vizualization-border-purple: #bba3ff !default;
$vizualization-sort-purple: #513a92 !default;
$vizualization-zebra-row-purple: #282030 !default;
$vizualization-purple-01: rgba(134, 115, 153, 0.9) !default;
$vizualization-purple-02: rgba(153, 117, 255, 0.24) !default;
$vizualization-purple-03: #231d30 !default;
$vizualization-green-1: #01fa0b !default;
$vizualization-green-100: #a1d730 !default;
$vizualization-green-200: #698431 !default;
$vizualization-green-300: #65cc01 !default;
$vizualization-green-400: #81a33b !default;
$vizualization-green-500: #3f4a44 !default;
$vizualization-orange-100: #ff912c !default;
$vizualization-orange-200: #a37851 !default;
$vizualization-orange-300: #4c433d !default;
$vizualization-yellow-100: #f1df3a !default;
$vizualization-yellow-200: #ccbf49 !default;
$vizualization-yellow-300: #4c483c !default;
$vizualization-red-100: #dc3b3b !default;
$vizualization-red-200: #d96868 !default;
$vizualization-red-300: #4c403f !default;
$vizualization-purple-1: #867399 !default;
$vizualization-purple-2: #635472 !default;
$vizualization-purple-3: #2c1a30 !default;
$vizualization-engage-pink: #dd92ef !default;
$vizualization-engage-green: #8affe3 !default;
$Viz-MFA-purple-1: #5c4699 !default;
$Viz-MFA-chip-bg: #25212e !default;
$structural-color-chip-BG: #bab3cc !default;
$vizualization-needle: linear-gradient(180deg, #58565b 0%, rgba(88, 86, 91, 0.34) 100%) !default;
$vizualization-gradient-card: linear-gradient(180deg, #2d2832 0%, rgba(45, 40, 50, 0.05) 100%) !default;
$vizualization-gradient-red-1: linear-gradient(180deg, #ef1111 0%, rgba(220, 59, 59, 0.44) 100%) !default;
$vizualization-gradient-bar-1: linear-gradient(180deg, #614d99 0%, rgba(97, 77, 153, 0.6) 100%) !default;
$vizualization-gradient-yellow-1: linear-gradient(180deg, #f1df3a 0%, rgba(241, 223, 58, 0.44) 100%) !default;
$vizualization-gradient-green-1: linear-gradient(180deg, #01fa0b 0%, rgba(1, 250, 11, 0.44) 100%) !default;
$vizualization-gradient-orange-1: linear-gradient(180deg, #ff912c 0%, rgba(255, 145, 44, 0.44) 100%) !default;
$vizualization-purple-chips: rgba(134, 115, 153, 0.24) !default;
$vizualization-red-chip: rgba(61, 21, 18, 0.24) !default;
$vizualization-gradient-chip-1: linear-gradient(
    180deg,
    rgba(97, 77, 153, 0.5) 0%,
    rgba(97, 77, 153, 0.3) 100%
) !default;
$vizualization-gradient-chip-02: linear-gradient(
    180deg,
    rgba(97, 77, 153, 0.16) 0%,
    rgba(97, 77, 153, 0) 100%
) !default;
$vizualization-gradient-purple: conic-gradient(
    from 180deg at 50% 50%,
    #9975ff 0deg,
    rgba(153, 117, 255, 0) 360deg
) !default;
$vizualization-gradient-1: linear-gradient(180deg, #58565b 0%, rgba(88, 86, 91, 0.34) 100%) !default;
$vizualization-purple-3: linear-gradient(180deg, #614d99 0%, rgba(97, 77, 153, 0.6) 100%) !default;
$vizualization-green: rgba(8, 61, 36, 0.24) !default;
$vizualization-orange: rgba(61, 34, 8, 0.24) !default;
$vizualization-black: rgba(0, 0, 0, 0.25) !default;
$vizualization-box-shadow: rgba(3, 1, 9, 0.2) !default;
$vizualization-integratedchip-bg: rgba(85, 77, 110, 0.24) !default;
$vizualization-black: rgba(0, 0, 0, 0.25) !default;
$vizualization-green-2: #1a3423 !default;
$vizualization-blue: #29536e !default;
$vizualization-green-01: #418357 !default;
$vizualization-purple: #713e7e !default;
$vizualization-blue-01: #10212c !default;
$vizualization-blue-02: #8ae3ff !default;
$vizualization-blue-03: #407092 !default;
$theme-colors: (
    'body-color': $body-color,
    'primary-100': $primary-100,
    'primary-300': $primary-300,
    'primary-400': $primary-400,
    'primary-500': $primary-500,
    'primary-700': $primary-700,
    'chip-red': $chip-red,
    'text-high-emphasis': $text-high-emphasis,
    'text-medium-emphasis': $text-medium-emphasis,
    'text-low-emphasis': $text-low-emphasis,
    'text-black': $text-black,
    'text-interactive': $text-interactive,
    'hover-login-cell': $hover-login-cell,
    'structural-BG': $structural-BG,
    'structural-stroke': $structural-stroke,
    'structural-stroke-100': $structural-stroke-100,
    'structural-stroke-200': $structural-stroke-200,
    'structural-stroke-300': $structural-stroke-300,
    'structural-stroke-500': $structural-stroke-500,
    'structural-stroke-600': $structural-stroke-600,
    'structural-9': $structural-9,
    'structural-CardBG': $structural-CardBG,
    'structural-selected': $structural-selected,
    'structural-200': $structural-200,
    'structural-300': $structural-300,
    'structural-400': $structural-400,
    'structural-badge-bg': $structural-badge-bg,
    'grey-50': $grey-50,
    'grey-100': $grey-100,
    'grey-300': $grey-300,
    'grey-400': $grey-400,
    'stroke-400': $stroke-400,
    'stroke-500': $stroke-500,
    'secondary-accent-red-1': $secondary-accent-red-1,
    'secondary-accent-red-2': $secondary-accent-red-2,
    'secondary-accent-red-3': $secondary-accent-red-3,
    'secondary-accent-red-4': $secondary-accent-red-4,
    'success-green': $success-green,
    'secondary-accent-blue-1': $secondary-accent-blue-1,
    'secondary-accent-blue-2': $secondary-accent-blue-2,
    'secondary-accent-blue-3': $secondary-accent-blue-3,
    'secondary-accent-yellow-1': $secondary-accent-yellow-1,
    'secondary-accent-yellow-2': $secondary-accent-yellow-2,
    'secondary-accent-yellow-3': $secondary-accent-yellow-3,
    'secondary-accent-orange-1': $secondary-accent-orange-1,
    'secondary-accent-orange-2': $secondary-accent-orange-2,
    'secondary-accent-orange-3': $secondary-accent-orange-3,
    'table-row-hover': $table-row-hover,
    'vizualization-border-purple': $vizualization-border-purple,
    'vizualization-sort-purple': $vizualization-sort-purple,
    'viz-zebra-row-purple': $vizualization-zebra-row-purple,
    'vizualization-purple-01': $vizualization-purple-01,
    'vizualization-purple-02': $vizualization-purple-02,
    'vizualization-green-1': $vizualization-green-1,
    'vizualization-green-100': $vizualization-green-100,
    'vizualization-green-200': $vizualization-green-200,
    'vizualization-green-300': $vizualization-green-300,
    'vizualization-green-400': $vizualization-green-400,
    'vizualization-green-500': $vizualization-green-500,
    'vizualization-orange-100': $vizualization-orange-100,
    'vizualization-orange-200': $vizualization-orange-200,
    'vizualization-orange-300': $vizualization-orange-300,
    'vizualization-yellow-100': $vizualization-yellow-100,
    'vizualization-yellow-200': $vizualization-yellow-200,
    'vizualization-yellow-300': $vizualization-yellow-300,
    'vizualization-red-100': $vizualization-red-100,
    'vizualization-red-200': $vizualization-red-200,
    'vizualization-red-300': $vizualization-red-300,
    'vizualization-purple-1': $vizualization-purple-1,
    'vizualization-purple-2': $vizualization-purple-2,
    'vizualization-purple-3': $vizualization-purple-3,
    'vizualization-green': $vizualization-green,
    'vizualization-orange': $vizualization-orange,
    'vizualization-black': $vizualization-black,
    'Viz-MFA-purple-1': $Viz-MFA-purple-1,
    'Viz-MFA-chip-bg': $Viz-MFA-chip-bg,
    'secondary-accent-grey-1': $secondary-accent-grey-1,
    'structural-color-chip-BG': $structural-color-chip-BG,
    'vizualization-integratedchip-bg': $vizualization-integratedchip-bg,
    'vizualization-box-shadow': $vizualization-box-shadow,
    'vizualization-blue': $vizualization-blue,
    'vizualization-green-01': $vizualization-green-01,
    'vizualization-purple': $vizualization-purple,
    'vizualization-blue-01': $vizualization-blue-01,
    'vizualization-blue-02': $vizualization-blue-02,
    'vizualization-blue-03': $vizualization-blue-03,
    'vizualization-green-2': $vizualization-green-2,
    'foundation-blue-200': $foundation-blue-200,
    'vizualization-engage-pink': $vizualization-engage-pink,
    'vizualization-engage-green': $vizualization-engage-green,
    'vizualization-purple-03': $vizualization-purple-03,
);

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
);
