@import '../../../theme/variables.scss';

.vendor-card {
    .card {
        min-width: 18.125rem;
        min-height: 18.5rem;
        border-radius: 0.5rem;
        padding: 1rem;
        position: relative;

        &:hover {
            border: 1px solid $text-interactive;
        }
    }

    .chip__integrated-card-variant {
        position: relative;
        top: 0.88rem;
        left: 0.75rem;
        opacity: 100%;
    }

    .disabled {
        margin-top: -1.6rem;
        opacity: 40%;
        cursor: not-allowed;
        pointer-events: none;
        border-radius: 0.5rem;

        &:hover {
            border: none;
        }
    }

    .body2 {
        margin: 0rem !important;
        line-height: 1rem;
    }

    .subtitle5,
    .body7 {
        margin: 0.1rem !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .divider {
        margin-top: 1.47rem;
        margin-bottom: 2.25rem;
        width: 14.6875rem;
        height: 0.0625rem;
        flex-shrink: 0;
    }

    &__image-text {
        margin-top: 1.5rem;
    }

    &__icon {
        height: 77px;
        margin-bottom: 0.8rem;
    }
}
