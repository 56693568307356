@import '../../../theme/variables.scss';
.canvas {
    &__canvas-styles {
        border-radius: 0px 0px 10px 10px;
    }
    &__prompt-item {
        .delete-button {
            visibility: hidden;
        }
        &:hover {
            cursor: pointer;
            background-color: rgba($primary-500, 0.1);
            border-radius: 0.25rem;
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            display: flex;
            align-items: center;
            .delete-button {
                cursor: pointer;
                background: none;
                border: none;
                visibility: visible !important;
                color: $text-high-emphasis;
            }
        }
    }
    &__dotted-border-card {
        border: 2px dashed $text-low-emphasis;
    }
}
