@import '../../../theme/variables.scss';

.sidebar {
    min-height: 44.31rem;
    flex-shrink: 0;
    min-width: 11.25rem;
    .subtitle4 {
        margin-bottom: 0rem;
    }
    .body1,
    .subtitle6,
    .body3 {
        margin-bottom: 0rem;
    }
    &__link {
        cursor: 'pointer';
        text-decoration: none;
    }

    &__ext-icon {
        color: $text-medium-emphasis;
    }
    &__typo-cont {
        height: calc(100% - 9rem);
    }
}
